import React, { useEffect, useState } from 'react';
import ProjectGrid from '../components/ProjectGrid';

const terms = [
  'Graphic Designer.',
  'Faiseur d\'images.',
  'Mangaka en devenir.',
  'Artisan du numérique.',
  'Créateur d\'expériences.',
  'Sculpteur d\'interfaces.',
  'J\'utilise Figma.',
  'Product Designer.',
  'Développeur de sites HTML/CSS.',
  'Développeur de site Wordpress.',
  'Musicien autodidacte.',
  'Crayonneur à temps partiel.',
  'Apprenti ReactJS.',
  'UI/UX Designer.',
  'J\'ai fait des vidéos.',
  'Connaît After Effects.',
  'Le responsive c\'est important.',
  'Maîtrise Affinity et Adobe.',
  'Je pense à l\'accessibilté.',
  'Worldbuilder.',
  'Ouvert à de nouvelles opportunités.',
  'Je suis sur LinkedIn.',
  'On dit que je suis sérieux.',
  'Fier d\'être Hmong',
  'Improbable.',
  'Professionnel.',
  'J\'adore rire.',
  'te remercie de ta visite.',
  'Adepte de l\'empirisme.',
  '7000h sur TF2.',
  'Je fais mes musiques',
  'Je créé un manga interactif !',
  'La vie est difficile.',
  'Can speak englsih fluently.',
  'Raconteur d\'histoires.',
  'Déjà trop aigri.',
  'Chasseur de sens.',
  'Philosophe de comptoir.',
  'J\'ai fait des compositions.',
  'Passionné d\'astronomie.',
  'Aime parler de tout.',
  'Adore la science.',
  'Rêveur aux pieds sur Terre.',
  'Le Maître Noir',
  'Musicien en herbe.',
  'Construit ce site.',
  'Curieux de ce monde.',
  'A peur de la fin de l\'Univers.',
  'Viewer de l\'ombre.',
  'J\'adore jouer du piano.',
];

const Hero = () => {
  const [currentTerm, setCurrentTerm] = useState('');
  const [index, setIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    setCurrentTerm(terms[index]);
    const interval = setInterval(() => {
      setIsVisible(false);
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % terms.length);
        setIsVisible(true);
      }, 750);
    }, 4000);

    return () => clearInterval(interval);
  }, [index]);

  return (
    <section>
      <div className="container-hero landing-page">
        <div className="container-hero-text difference">
          <div>
            <h1 className="white display">Anthony TCHA</h1>
            <h2 className={`white display regular term ${isVisible ? 'translate-in' : 'translate-out'}`}>
              {currentTerm}
            </h2>
            <p className="white sans-serif">Mais pas que. Bienvenue dans mon portfolio.</p>
          </div>
        </div>
        <div className="hero-image-container yellow-background parallax-container">
          <img src="images/ma-photo.jpg" alt="Portrait d'Anthony TCHA" className="hero-image landing-page sticky-image" />
        </div>
      </div>
      <div>
        <ProjectGrid />
      </div>
    </section>
  );
};

export default Hero;
export const projects = [
    {
        id: 0,
        title: "KURO NO MASTER",
        link: "kuronomaster",
        description: "Le projet de ma vie. Mon manga interactif.",
        imgSrc: "images/project/kuronomaster/kuronomaster-logo.jpg",
        imgAlt: "Logo du manga KURO NO MASTER",
        href: "https://www.kuronomaster.com",
        url: "kuronomaster.com",
        tags: "Identité visuelle. Illustration. Édition. Développement. Musique. Webdesign.",
        sections: [
            {
                title: "Le concept",
                content: "KURO NO MASTER est mon projet de manga interactif en ligne où vos choix détermineront la suite de l’histoire."
            },
            {
                imgSrc: "images/project/kuronomaster/character-design-clothes.jpg",
                imgAlt: "Logo du manga KURO NO MASTER",
            },
            {
                title: "Le fond",
                content: "« Existant depuis l’aube de l’humanité, les Montres de Pouvoir octroient des capacités altérant la réalité à leurs Porteurs, des femmes et hommes d'exception dont les noms sont encore gravés dans les mémoires. Aujourd’hui, ces cinq jeunes ont été choisis pour être leurs Porteurs. Ils prendront conscience de leur responsabilité et devront faire des choix cruciaux qui impacteront l’Histoire. »"
            },
            {
                title: "La forme",
                content: "C'est un manga différent. Un style graphique crayonné viscéral, pour capturer l'essence des expressions et émotions. Un mode de lecture unique, où l'attention est concentrée sur une case."
            },
            {
                imgSrc: "images/project/kuronomaster/laurent-de-kern-portrait-1.jpg",
                imgAlt: "Logo du manga KURO NO MASTER",
            },
            {
                title: "L'objectif",
                content: "KURO NO MASTER est un projet de longue date. Depuis 2015, de simples croquis de personnages ont donné cet univers foisonnant. Mes compétences en design, illustration, narration, interactivité, musique, seraient-elles les fruits de l'influence du KURO NO MASTER ?"
            }
        ]
    },
    {
        id: 1,
        title: "Les Lucioles Silencieuses",
        link: "les-lucioles-silencieuses",
        description: "Évènement de Street Art urbain nantais.",
        imgSrc: "images/project/lucioles-silencieuses/lucioles-silencieuses-logo.jpg",
        imgAlt: "Logo de l'évènement des Lucioles Silencieuses",
        tags: "Identité visuelle. Print. Vidéo. Évènementiel."
    },
    {
        id: 2,
        title: "Cadence",
        link: "cadence",
        description: "Application de cadavre exquis musical.",
        imgSrc: "images/project/cadence/cadence-logo.png",
        imgAlt: "Logo de l'application web Cadence",
        tags: "Identité visuelle. Communication. Motion Design. Webdesign."
    },
    {
        id: 3,
        title: "TAIGA",
        link: "taiga",
        description: "Identité de marque de manteaux d'hiver.",
        imgSrc: "images/project/taiga/taiga-logo.jpg",
        imgAlt: "Logo de la marque de vêtements TAIGA",
        tags: "Identité visuelle. Print. Mockups."
    },
    {
        id: 4,
        title: "UpCup'",
        link: "upcup",
        description: "Site évènement pour mugs personnalisés.",
        imgSrc: "images/project/upcup/upcup-logo-packshot.jpg",
        imgAlt: "Logo de la marque de tasse UpCup'",
        tags: "Webdesign."
    },
    {
        id: 5,
        title: "Colis",
        link: "colis",
        description: "Fanzine, satire de l'emballage.",
        imgAlt: "Illustration stylisé de tapis roulant de colis",
        imgSrc: "images/project/colis/colis.jpg",
        tags: "Print. Édition."
    },
    {
        id: 6,
        title: "Pérégrination",
        link: "peregrination",
        description: "Notre petitesse en peinture face à l'environnement.",
        imgSrc: "images/project/peregrination/peregrination.jpg",
        imgAlt: "Peinture jaune et violette de désert",
        tags: "Illustration."
    },
    {
        id: 7,
        title: "Cristaux",
        link: "cristaux",
        description: "Triangularisation vectorielle de minéraux.",
        imgSrc: "images/project/cristaux/quartz-noir.svg",
        imgAlt: "Illustration de quartz noir triangularisé",
        tags: "Illustration."
    },
    {
        id: 8,
        title: "Suspects sinistres",
        link: "suspects-sinistres",
        description: "Portraits photographiques low-key.",
        imgSrc: "images/project/suspects-sinistres/demon.jpg",
        imgAlt: "Visage en low-key",
        tags: "Photographie. Retouche photo."
    },
    {
        id: 9,
        title: "Photomontages divers",
        link: "photomontages",
        description: "Petits bricolages photographiques d'élements visuels.",
        imgSrc: "images/project/photomontage/dauphin-manchot.jpg",
        imgAlt: "Photomontage de dauphins-manchots",
        tags: "Retouche photo."
    },
    {
        id: 10,
        title: "Mister Coocky",
        link: "mister-coocky",
        description: "Plateforme d'articles pour animaux de compagnie.",
        imgSrc: "images/project/mister-coocky/mister-coocky-logo.jpg",
        imgAlt: "Logo de l'application Mister Coocky",
        tags: "Identité visuelle. Print. Vidéo. Webdesign. Application. Réseaux sociaux."
    },
    {
        id: 11,
        title: "Jeux de m'eau",
        link: "jeux-de-meau",
        description: "Mise en image d'expressions liées à l'eau.",
        imgSrc: "images/project/jeux-de-meau/se-mefier-de-leau-qui-dort.jpg",
        imgAlt: "Illustration amusante d'un bonhomme qui va se faire engloutir par des vagues",
        tags: "Illustration."
    },
    {
        id: 12,
        title: "GreenTwins",
        link: "green-twins",
        description: "Site vitrine et webapp de monitoring et de gestion de l'énergie.",
        imgSrc: "images/project/greentwins/logo-green-twins.jpg",
        imgAlt: "Logo du projet GreenTwins",
        tags: "Webdesign. Identité visuelle. Print."
    },
    {
        id: 13,
        title: "Conception de logos",
        link: "logos",
        description: "Création en tous genres de logotypes.",
        imgSrc: "images/project/logo/logo-lhonore.jpg",
        imgAlt: "Logo de l'évènement de mariage L'Honoré",
        tags: "Identité visuelle."
    },
    {
        id: 14,
        title: "Rezé Histoire",
        link: "reze-histoire",
        description: "Site vitrine associatif du sauvegarde du patrimoine historique de Rezé.",
        imgSrc: "images/project/reze-histoire/logo-reze-histoire.jpg",
        imgAlt: "Logo de l'association Rezé Histoire",
        tags: "Développement. Wordpress."
    }
];
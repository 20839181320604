import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { projects } from '../data/projectData';
import ProjectGridMin from './ProjectGridMin';
import ProjectGrid from './ProjectGrid';

const ProjectPage = () => {
    const { id } = useParams();
    const project = projects.find(p => p.link === id);
    const [isHovered, setIsHovered] = useState(false);

    if (!project) {
        return (
            <section className="project-page">
                <img src="images/404.png" alt="Erreur 404" className="project-image error-404 fixed-image" />
                <div className="project-header">
                    <h1 className="display">Ah, vous vous êtes égarés.</h1>
                    <h2 className="display yellow regular small-text">Ça tombe bien, il y a bien d'autres belles choses en dessous.</h2>
                    <hr className='yellow-background' />
                </div>
                <ProjectGrid />
            </section>
        );
    }

    return (
        <section>
            <div>
                <img src={`../${project.imgSrc}`} alt={project.imgAlt} className="project-image fixed-image" />
            </div>

            <div className="project-header">
                <h1 className="display">{project.title}</h1>
                <p className="display yellow small-text">{project.tags}</p>
                <hr />
                <p className="sans-serif gray">{project.description}</p>
                {project.url && (
                    <a
                        href={project.href}
                        className={`project-links ${isHovered ? 'hovered' : 'no-hover'}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        {project.url}
                    </a>
                )}
            </div>

            <div className="project-content">
                {project.sections && project.sections.map((section, index) => (
                    <div key={index}>
                        {section.title && (<h3 className="display bold project-content-text">{section.title}</h3>)}
                        {section.content && (<p className="sans-serif regular project-content-text">{section.content}</p>)}
                        {section.imgSrc && (
                            <img
                                src={`../${section.imgSrc}`}
                                alt={section.imgAlt}
                                className="project-image fixed-image"
                            />
                        )}
                    </div>
                ))}
            </div>
            <ProjectGridMin />
        </section>
    );
};

export default ProjectPage;